/** @typedef {import("./types").ApiResponse} ApiResponse */
/** @typedef {import("./types").BookmarkPage} BookmarkPage */

import { LitElement, html, render } from 'lit';

const { app } = window;

class TwBookmarkAdd extends LitElement {
	static properties = {
		complete: { type: Boolean },
		data: { type: Object },
		category: { type: String },
		loading: { type: Boolean },
	};

	/** @type {LitElement} */
	get #dialogElement() {
		return this.renderRoot.querySelector('tw-dialog');
	}

	/** @type {HTMLFormElement} */
	get #formElement() {
		return this.renderRoot.querySelector('form');
	}

	/** @type {HTMLUListElement} */
	// eslint-disable-next-line class-methods-use-this
	get #bookmarksList() {
		return document.querySelector('.m-bookmarks-list');
	}

	constructor() {
		super();
		this.complete = false;
		/** @type {BookmarkPage|object} */
		this.data = {};
		this.loading = true;
	}

	/** @param {SubmitEvent} event */
	async #onSubmit(event) {
		event.preventDefault();
		const buttonElement = event.submitter;
		buttonElement.toggleAttribute('data-loading', true);
		try {
			const formData = new FormData(this.#formElement);
			const data = Object.fromEntries(formData.entries());
			await this.save(data);
			await this.#dialogElement.close();
			this.remove();
		} catch (error) {
			alert(error.message);
		}
		buttonElement.toggleAttribute('data-loading', false);
	}

	#addBookmarkListItem(data) {
		render(
			html`
				<li>
					<tw-bookmark .complete="${true}" .data="${data}"></tw-bookmark>
				</li>
			`,
			this.#bookmarksList,
			{ renderBefore: this.#bookmarksList.firstElementChild },
		);
	}

	async save(data) {
		if (data.url) {
			this.loading = true;
			const { category } = this;
			const responseData = await app.fetch(`bookmark/${category}?view=create`, 'POST', data);
			if (responseData.status === 'ok') {
				this.data = responseData.data;
				this.#addBookmarkListItem(responseData.data);
			}
			this.loading = false;
		}
	}

	render() {
		return html`
			<link rel="stylesheet" href="assets/css/tw-bookmark-add.${BUILT_AT}.css">
			<tw-dialog size="small">
				<header>
					<h2>Add bookmark</h2>
				</header>
				<div class="main-content">
					<tw-form>
						<form id="from" action="api/bookmark" method="post" @submit=${this.#onSubmit}>
							<tw-field label="URL"><input class="a-input" type="text" id="url" name="url" autofocus></tw-field>
							<tw-field label="Tags"><input class="a-input" type="text" id="tags" name="tags"></tw-field>
							<tw-field label="Note"><textarea class="a-input" id="note" name="note" rows=3></textarea></tw-field>
						</form>
					</tw-form>
				</div>
				<footer class="buttons">
					<div class="buttons__primary">
						<button class="a-button" data-full-width form="from">
							Create
						</button>
					</div>
				</footer>
			</tw-dialog>
		`;
	}
}

customElements.define('tw-bookmark-add', TwBookmarkAdd);
