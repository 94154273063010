/* eslint-disable class-methods-use-this */

class Durations {
	static get(value = 'medium') {
		const cssProperty = `--duration-${value}`;
		const duration = parseFloat(getComputedStyle(document.documentElement)
			.getPropertyValue(cssProperty));
		return Number.isFinite(duration) ? duration : 200;
	}

	static getEase(value = 'ease') {
		const cssProperty = `--ease-${value}`;
		return getComputedStyle(document.documentElement).getPropertyValue(cssProperty);
	}
}

export default Durations;
