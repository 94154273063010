const element = document.querySelector('.t-bookmark-category');

if (element) {
	const formElement = element?.querySelector('.t-default__search-form');

	requestAnimationFrame(() => {
		if (document.scrollingElement.scrollTop < formElement.offsetHeight) {
			document.scrollingElement.scrollTo({
				top: formElement.offsetHeight,
				behavior: 'instant',
			});
		}
	});
}
