/* eslint-disable class-methods-use-this */
/** @typedef {import("./types").ApiResponse} ApiResponse */
/** @typedef {App} AppDef */

const { csrf } = document.body.dataset;

class App {
	/** @type {HTMLElement[]}  List of modals and similar, used to close on escape key press */
	#modals = [];

	/** @type {?HTMLElement} */
	#themeColorMetaElement;

	/** @type {?string} */
	#defaultThemeColor;

	constructor() {
		this.#themeColorMetaElement = document.head.querySelector('[name="theme-color"]:not([media])');
		this.#defaultThemeColor = this.#themeColorMetaElement?.getAttribute('content');

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				const foremostModal = this.#modals.pop();
				if (foremostModal) {
					foremostModal.close();
				}
			}
		});
	}

	set noScroll(value) {
		document.documentElement.toggleAttribute('data-no-scroll', value === true);
	}

	get noScroll() {
		return document.documentElement.getAttribute('data-no-scroll') === 'true';
	}

	/** @param {?string} color */
	updateThemeColor(color) {
		if (color) {
			this.#themeColorMetaElement?.setAttribute('content', color);
		}
	}

	/** @param {string} variableName - CSS variable name including `--` */
	/** @param {?HTMLElement} element */
	getCssVariableValue(variableName, element) {
		const computedStyle = getComputedStyle(element ?? document.documentElement);
		return computedStyle.getPropertyValue(variableName);
	}

	resetThemeColor() {
		if (this.#defaultThemeColor) {
			this.#themeColorMetaElement?.setAttribute('content', this.#defaultThemeColor);
		}
	}

	openAddDialog(category) {
		document.body.appendChild(Object.assign(document.createElement('tw-bookmark-add'), {
			category,
		}));
	}

	async fetch(route, method = 'GET', newData = null) {
		const options = {
			method,
			headers: {
				'x-csrf': csrf,
			},
		};
		if (newData) {
			options.body = JSON.stringify(newData);
		}
		const response = await fetch(`/api/${route}`, options).catch(() => {
			throw new Error('Fatal Error');
		});

		/** @type {ApiResponse} */
		const responseData = await response.json().catch(() => { throw new Error('Fatal Error'); });

		if (responseData.status === 'error') {
			throw new Error(responseData.message ?? 'Fatal Error');
		}

		return responseData;
	}
}

window.app = new App();

export default App;
