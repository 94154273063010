/** @typedef {import("./types").BookmarkPage} BookmarkPage */
/** @typedef {import("./app").AppDef} AppDef */

import { LitElement, html } from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import icons from '../foundation/icons';

const xmark = `${icons['xmark-500']}`;
const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw';
const { lang } = document.documentElement;
const { app } = window;

class TwBookmarkDetails extends LitElement {
	static properties = {
		complete: { type: Boolean },
		data: { type: Object },
		loading: { type: Boolean },
	};

	/** @type {LitElement} */
	get #dialogElement() {
		return this.renderRoot.querySelector('tw-dialog');
	}

	/** @type {HTMLFormElement} */
	get #formElement() {
		return this.renderRoot.querySelector('form');
	}

	/** @type {?string} page id */
	get #id() {
		return this.data?.id;
	}

	constructor() {
		super();
		this.complete = false;
		/** @type {BookmarkPage|object} */
		this.data = {};
		this.loading = false;
	}

	async close() {
		await this.#dialogElement.close();
	}

	async #fetch(method = 'GET', newData = null, path = '') {
		try {
			this.loading = true;
			const responseData = await app.fetch(`bookmark/${this.#id}/${path}?view=details`, method, newData);
			if (responseData.data) {
				this.data = responseData.data;
			}
			this.loading = false;
			return responseData.data;
		} catch (error) {
			console.info(error);
			alert(error.message);
			throw error;
		}
	}

	async loadData() {
		if (this.#id) {
			try {
				await this.#fetch();
				this.complete = true;
			} catch (error) {
				this.remove();
			}
		}
	}

	async delete() {
		this.#formElement.dispatchEvent(new Event('submit'));

		const formData = new FormData(this.#formElement);
		const newData = Object.fromEntries(formData.entries());

		await this.#fetch('DELETE', newData);

		await this.close();

		this.dispatchEvent(new Event('delete'));
	}

	async patch() {
		this.#formElement.dispatchEvent(new Event('submit'));

		const formData = new FormData(this.#formElement);
		const newData = Object.fromEntries(formData.entries());

		const data = await this.#fetch('PATCH', newData);

		this.dispatchEvent(new Event('update', data));

		this.close();
	}

	#onUpdateClick(event) {
		event.preventDefault();
		this.patch();
	}

	async #onFetchClick(event) {
		event.preventDefault();
		const buttonElement = event.target;
		buttonElement.toggleAttribute('data-loading', true);
		try {
			await this.#fetch('GET', null, 'fetch');
		} finally {
			buttonElement.toggleAttribute('data-loading', false);
		}
	}

	#onMoveClick(event) {
		event.preventDefault();
		this.move();
	}

	#onDeleteClick(event) {
		event.preventDefault();
		this.delete();
	}

	connectedCallback() {
		super.connectedCallback();
		this.loadData();
	}

	render() {
		const {
			images,
			dateCreated,
			title,
			url,
			description,
			tags,
			note,
		} = this.data;

		const domain = (new URL(url)).hostname.replace(/^www\./, '');

		let formattedDate;
		if (dateCreated) {
			const dateObject = new Date(dateCreated);
			formattedDate = new Intl.DateTimeFormat(lang, {
				dateStyle: 'short',
				timeStyle: 'short',
			}).format(dateObject);
		}

		return html`
			<link rel="stylesheet" href="assets/css/tw-bookmark-details.${BUILT_AT}.css">
			<tw-dialog @close=${this.remove}>
				<header>
					<!-- <button class="a-button" aria-label="Close" @click=${this.close}>${unsafeSVG(xmark)}</button> -->
					<h2>${title}</h2>
				</header>
				<div class="main-content">
					<div class="preview">
						<a class="image-link" href="${url}" tabindex="-1">
							<img class="image" src="${images?.card.url ?? emptyImage}" srcset="${images?.card.srcset}" alt="" sizes="384px" width="64" height="40" loading="lazy">
						</a>
						<div class="meta">
							<span>${domain}</span>
							<time datetime=${dateCreated}>${formattedDate}</time>
						</div>
						<div class="description">
							${description}
						</div>
					</div>
					<tw-form>
						<form id="from">
							<tw-field label="Title"><input class="a-input" type="text" id="title" name="title" value="${title}"></tw-field>
							<tw-field label="URL"><input class="a-input" type="url" id="url" name="url" value="${url}"></tw-field>
							<tw-field label="Tags"><input class="a-input" type="text" id="tags" name="tags" value="${tags}"></tw-field>
							<tw-field label="Note"><textarea class="a-input" id="note" name="note" rows=3 .value="${note}"></textarea></tw-field>
						</form>
					</tw-form>
				</div>
				<footer class="buttons">
					<div class="buttons__secondary">
						<button class="a-button" data-kind="secondary" data-full-width @click=${this.#onFetchClick}>
							Fetch
						</button>
						<button class="a-button" data-kind="secondary" data-full-width @click=${this.#onMoveClick}>
							Move
						</button>
						<button class="a-button" data-kind="secondary" data-theme="negative" data-full-width @click=${this.#onDeleteClick}>
							Delete
						</button>
					</div>
					<div class="buttons__primary">
						<button class="a-button" data-full-width form="from" @click=${this.#onUpdateClick}>
							Update
						</button>
					</div>
				</footer>
			</tw-dialog>
		`;
	}
}

customElements.define('tw-bookmark-details', TwBookmarkDetails);
