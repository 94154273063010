import { LitElement, html, css } from 'lit';

customElements.define('tw-field', class extends LitElement {
	static properties = {
		label: { type: String },
		id: { type: String },
	};

	connectedCallback() {
		super.connectedCallback();
	}

	/** @param {Event} event - The slotchange event. */
	handleSlotChange(event) {
		/** @type {HTMLSlotElement} */
		const slot = event.target;
		const assignedNodes = slot.assignedNodes({ flatten: true });
		if (assignedNodes.length > 0) {
			this.id = assignedNodes[0].id;
		}
	}

	render() {
		const {
			id,
		} = this;

		return html`
			${this.label ? html`<label for="${id}">${this.label}</label>` : null}
			<div class="input">
				<slot @slotchange=${this.handleSlotChange}></slot>
			</div>
		`;
	}

	static styles = css`
		:host {
			display: grid;
			grid-template-columns: auto auto;
			row-gap: var(--gap-xsmall);
			column-gap: var(--gap-small);
		}
		label {
			font: var(--font-label-small);
			color: var(--color-content-secondary);
		}
		.input {
			grid-column: 1 / -1;
		}
	`;
});
