import { LitElement, html } from 'lit';
import Durations from '../foundation/durations';

const { app } = window;

/**
 * @typedef {Object} TouchObject
 * @property {TouchEvent} last
 * @property {Object} delta
 * @property {Number} delta.x
 * @property {Number} delta.y
 * @property {Object} deltaStart
 * @property {Number} deltaStart.x
 * @property {Number} deltaStart.y
 */

class TwDialog extends LitElement {
	/** @type {?TouchObject} */
	#touch;

	/** @type {?String} – indicates whether user scrolls `x` or `y` */
	#scrollLock;

	/** @type {Number} */
	#translateY = null;

	/** @type {HTMLDialogElement} */
	get #dialogElement() {
		return this.renderRoot.querySelector('dialog');
	}

	get #containerElement() {
		return this.renderRoot.querySelector('.container');
	}

	close() {
		return new Promise((resolve) => {
			app.noScroll = false;

			this.#dialogElement.animate([
				{
					backgroundColor: 'transparent',
					backdropFilter: 'none',
					webkitBackdropFilter: 'none',
				},
			], {
				duration: Durations.get('large'),
				easing: Durations.getEase(),
			});

			let containerKeyframes = [
				{
					translate: '0 100%',
				},
			];
			if (matchMedia('(min-width: calc(832rem / 16))').matches) {
				containerKeyframes = [
					{
						opacity: 0,
						scale: 0.98,
						translate: '0 calc(16rem / 16)',
					},
				];
			}
			this.#containerElement.animate(containerKeyframes, {
				duration: Durations.get('large'),
				easing: Durations.getEase(),
			}).addEventListener('finish', () => {
				this.remove();
				resolve();
				this.dispatchEvent(new Event('close'));
			});

			app.resetThemeColor();
		});
	}

	show() {
		app.noScroll = true;

		this.#dialogElement.show();

		this.#dialogElement.animate([
			{
				backgroundColor: 'transparent',
				backdropFilter: 'none',
				webkitBackdropFilter: 'none',
			},
			{
			},
		], {
			duration: Durations.get('xlarge'),
			easing: Durations.getEase(),
		});

		let containerKeyframes = [
			{
				translate: '0 100%',
			},
			{},
		];
		if (matchMedia('(min-width: calc(832rem / 16))').matches) {
			containerKeyframes = [
				{
					opacity: 0,
					scale: 0.98,
					translate: '0 calc(16rem / 16)',
				}, {},
			];
		}
		this.#containerElement.animate(containerKeyframes, {
			duration: Durations.get('large'),
			easing: Durations.getEase('out'),
		});

		app.updateThemeColor(app.getCssVariableValue('--color-backdrop-theme'));

		this.#dialogElement.addEventListener('close', this.close.bind(this));
	}

	connectedCallback() {
		super.connectedCallback();

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.close();
			}
		});
	}

	async firstUpdated() {
		await this.#dialogElement.updateComplete;
		this.show();

		this.#containerElement.addEventListener('touchstart', (event) => {
			const touch = event.touches[0];
			this.#touch = {
				last: touch,
			};
			this.#scrollLock = null;
			this.#translateY = null;
		});
		this.#containerElement.addEventListener('touchmove', (event) => {
			if (this.#containerElement.scrollTop <= 0) {
				const touch = event.touches[0];

				this.#touch = {
					last: touch,
					delta: {
						x: touch.clientX - this.#touch.last.clientX,
						y: touch.clientY - this.#touch.last.clientY,
					},
					deltaStart: {
						x: (this.#touch.deltaStart?.x ?? 0) + touch.clientX - this.#touch.last.clientX,
						y: (this.#touch.deltaStart?.y ?? 0) + touch.clientY - this.#touch.last.clientY,
					},
				};

				if (this.#scrollLock === null) {
					this.#scrollLock = Math.abs(this.#touch.deltaStart.y) >= Math.abs(this.#touch.deltaStart.x) ? 'y' : 'x';
				}

				this.#translateY = this.#scrollLock === 'y' ? this.#touch.deltaStart.y : 0;
				if (this.#translateY > 0) {
					event.preventDefault();
					this.#containerElement.style.translate = `0 ${this.#translateY}px`;
				} else {
					this.#containerElement.style.translate = null;
				}
			}
		});
		document.addEventListener('touchend', () => {
			if (
				((this.#translateY / window.innerHeight > 0.5))
				|| (this.#touch?.delta?.y > 7)
			) {
				this.close();
			} else if (this.#containerElement.style.translate) {
				this.#containerElement.animate({
					translate: '0 0',
				}, {
					duration: Durations.get('medium'),
				}).addEventListener('finish', () => {
					this.#containerElement.style.translate = null;
				});
			}
			this.#touch = null;
			this.#scrollLock = null;
			this.#translateY = null;
		});
		this.renderRoot.addEventListener('click', (event) => {
			if (event.target === this.#dialogElement) {
				this.close();
			}
		});
	}

	// eslint-disable-next-line class-methods-use-this
	render() {
		return html`
			<link rel="stylesheet" href="assets/css/tw-dialog.${BUILT_AT}.css">
			<dialog>
				<div class="container">
					<slot></slot>
				</div>
			</dialog>
		`;
	}
}

customElements.define('tw-dialog', TwDialog);
