import {
	LitElement,
	html,
	css,
	nothing,
} from 'lit';

/**
 * First element has to be a form element.
 */
customElements.define('tw-form', class extends LitElement {
	static properties = {
		action: { type: String },
		method: { type: String },
		name: { type: String },
		id: { type: String },
	};

	get formElement() {
		return this.shadowRoot.querySelector('slot').assignedElements()[0];
	}

	firstUpdated() {
		this.formElement.addEventListener('submit', (event) => {
			console.log('submit');
			event.preventDefault();
		});
	}

	onsubmit() {

	}

	render() {
		return html`
			<link rel="stylesheet" href="assets/css/tw-form.${BUILT_AT}.css">
			<slot>
		`;
	}
});
