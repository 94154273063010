/** @typedef {import("./types").ApiResponse} ApiResponse */
/** @typedef {import("./types").TouchObject} TouchObject */

import {
	LitElement,
	html,
} from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import Durations from '../foundation/durations';
import icons from '../foundation/icons';

const ellipsisCircleFill = `${icons['ellipsis-700']}`;
const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw';

const { csrf } = document.body.dataset;

/** Size of button when item moved from right to left (touch) */
const buttonEditRevealSize = 80;

class TwBookmark extends LitElement {
	static properties = {
		complete: { type: Boolean },
		data: { type: Object },
		loading: { type: Boolean },
	};

	/** @type {?TouchObject} */
	#touch;

	/** @type {?String} – indicates whether user scrolls `x` or `y` */
	#scrollLock;

	/** @type {Number} */
	#translateX = 0;

	/** @type {?Boolean} – true, when user starts touch on left
	 * edge of device (wants to go back in history) */
	#preventRefeal;

	/** @type {?Number} */
	#revealPercent;

	get #containerElement() {
		return this.renderRoot.querySelector('.container');
	}

	get #buttonEditElement() {
		return this.renderRoot.querySelector('.button-edit');
	}

	get #linkElement() {
		return this.renderRoot.querySelector('.link');
	}

	/** @type {?string} page id */
	get #id() {
		return this.data?.id ?? null;
	}

	constructor() {
		super();
		this.complete = false;
		/** @type {BookmarkPage|object} */
		this.data = {};
		this.loading = true;
	}

	connectedCallback() {
		super.connectedCallback();

		document.addEventListener('scroll', () => {
			if (window.matchMedia('(pointer: coarse)').matches) {
				this.hideReveal();
			}
		}, {
			passive: true,
		});
	}

	firstUpdated() {
		this.loadData();

		this.#containerElement.addEventListener('touchstart', (event) => {
			const touch = event.touches[0];
			this.#preventRefeal = touch.clientX <= 10;
			this.#touch = {
				last: touch,
			};
			this.#scrollLock = null;
		});
		this.#containerElement.addEventListener('touchmove', (event) => {
			if (this.#preventRefeal === false) {
				const touch = event.touches[0];

				this.#touch = {
					last: touch,
					delta: {
						x: touch.clientX - this.#touch.last.clientX,
						y: touch.clientY - this.#touch.last.clientY,
					},
					deltaStart: {
						x: (this.#touch.deltaStart?.x ?? 0) + touch.clientX - this.#touch.last.clientX,
						y: (this.#touch.deltaStart?.y ?? 0) + touch.clientY - this.#touch.last.clientY,
					},
				};

				if (this.#scrollLock === null) {
					this.#scrollLock = Math.abs(this.#touch.deltaStart.y) >= Math.abs(this.#touch.deltaStart.x) ? 'y' : 'x';

					if (this.#scrollLock) {
						// hide reveals from other tw-bookmark elements
						[...document.querySelectorAll('tw-bookmark')].forEach((twBookmarkElement) => {
							if (twBookmarkElement !== this) {
								twBookmarkElement.hideReveal();
							}
						});
					}
				}

				if (this.#scrollLock === 'x' && touch.clientX > 0) {
					// console.log(touch.clientX);
					event.preventDefault();
					this.#translateX += this.#touch.delta.x;
					this.#revealPercent = (this.#translateX * -1) / buttonEditRevealSize;
					this.updateReveal();
				}
			}
		});
		this.#containerElement.addEventListener('touchend', () => {
			if (this.#preventRefeal === false) {
				if (this.#scrollLock === 'x') {
					const percent = this.#revealPercent;
					if (percent > 1) {
						this.showReveal();
						if (percent > 2) {
							this.showDetails();
						}
					} else {
						this.hideReveal();
					}

					this.#scrollLock = null;
					this.#touch = null;
				}
			}
		});
	}

	showReveal() {
		const inlineSize = `${buttonEditRevealSize}`;
		this.#buttonEditElement.animate({
			inlineSize: `${inlineSize}px`,
		}, {
			duration: Durations.get('small'),
			easing: Durations.getEase(),
		}).addEventListener('finish', () => {
			this.#buttonEditElement.style.inlineSize = `${inlineSize}px`;
		});
		this.#linkElement.animate({
			translate: `${inlineSize * -1}px`,
		}, {
			duration: Durations.get('small'),
			easing: Durations.getEase(),
		}).addEventListener('finish', () => {
			this.#linkElement.style.translate = `${inlineSize * -1}px`;
		});
	}

	hideReveal() {
		this.#buttonEditElement.toggleAttribute('data-pressed', false);
		this.#buttonEditElement.animate({
			inlineSize: '0',
		}, {
			duration: Durations.get('small'),
			easing: Durations.getEase(),
		}).addEventListener('finish', () => {
			this.#buttonEditElement.style.inlineSize = null;
			this.#translateX = 0;
		});
		this.#linkElement.animate({
			translate: '0',
		}, {
			duration: Durations.get('small'),
			easing: Durations.getEase(),
		}).addEventListener('finish', () => {
			this.#linkElement.style.translate = null;
		});
	}

	updateReveal() {
		const percent = this.#revealPercent;
		this.#linkElement.style.translate = this.#translateX <= 0 ? `${this.#translateX}px` : null;
		this.#buttonEditElement.style.inlineSize = `${buttonEditRevealSize * percent}px`;
		this.#buttonEditElement.style.setProperty('--opacity-icon', percent <= 1 ? percent : 1);
		this.#buttonEditElement.toggleAttribute('data-pressed', (percent > 2));
	}

	async loadData() {
		if (this.#id && this.complete === false) {
			const response = await fetch(`/api/bookmark/${this.#id}?view=thumb`, {
				headers: {
					'x-csrf': csrf,
				},
			});
			this.loading = false;

			/** @type {ApiResponse} */
			const responseData = await response.json();
			const { data } = responseData;

			this.data = data;

			this.complete = true;
		}
	}

	remove() {
		const liElement = this.parentElement;
		liElement.animate([{
			opacity: 1,
			height: `${liElement.offsetHeight}px`,
		}, {
			opacity: 0,
			height: 0,
		}], {
			duration: Durations.get('medium'),
		}).addEventListener('finish', () => {
			liElement.remove();
		});
	}

	showDetails() {
		const detailsElement = document.createElement('tw-bookmark-details');
		detailsElement.data = this.data;
		document.body.appendChild(detailsElement);
		detailsElement.addEventListener('close', () => {
			this.hideReveal();
		});
		detailsElement.addEventListener('update', () => {
			this.data = detailsElement.data;
		});
		detailsElement.addEventListener('delete', () => {
			this.remove();
		});
	}

	render() {
		const {
			images,
			tags,
			title,
			url,
		} = this.data;

		const domain = url ? (new URL(url)).hostname.replace(/^www\./, '') : null;

		return html`
			<link rel="stylesheet" href="assets/css/tw-bookmark.${BUILT_AT}.css">
			<div class="container">
				<a href="${url}" class="link" rel="noopener">
					<img class="image" src="${images?.card.url ?? emptyImage}" alt="" width="64" height="34" loading="lazy">
					<strong class="title">${title}</strong>
					<div class="meta">
						<span>${domain}</span>
						<span>${tags?.map((_) => `#${_} `)}</span>
					</div>
				</a>
				<button class="button-edit" aria-label="Edit" @click="${this.showDetails}">
					<span>
						${unsafeSVG(ellipsisCircleFill)}
						<span>Edit</span>
					</span>
				</button>
			</div>
		`;
	}
}

customElements.define('tw-bookmark', TwBookmark);
